import { useRoute } from 'vue-router';
import { actions } from '@/store';

export default function useUrlParams(fn) {
    const route = useRoute();
    const { 
        token = '', 
        device = '', 
        companies = '',
        backSubdomain = process.env.VUE_APP_API_SUBDOMAIN,
        streamSubdomain = process.env.VUE_APP_STREAM_SUBDOMAIN,
    } = route.query;

    actions.setBackSubdomain(backSubdomain);
    actions.setStreamSubdomain(streamSubdomain);
    actions.setToken(token);
    actions.setDevice(device);
    actions.setCompanies(companies.split(','));
};
