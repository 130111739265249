<template>
	<div class="relative w-screen h-screen overflow-hidden bg-gray-800">
		<header class="px-8 py-12 absolute inset-x-0 top-0 flex justify-between items-center z-10">
			<div class="flex items-center space-x-2">
				<img src="@/assets/images/logo_new.png" />
			</div>

			<div class="space-x-4">
				<button 
					v-if="!loading"
					class="button"
					@click="openEmployeesList"
				>
					<svg class="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" data-inject-url="https://app.sesametime.com/img/icon-users.c13ea4d3.svg" aria-label="icon-users">
						<g>
							<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM3 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2m1-17.87a4 4 0 0 1 0 7.75M21 21v-2a4 4 0 0 0-3-3.85"></path>
						</g>
					</svg>


					<EmployeesList 
						v-model:open="isEmployeesListOpen" 
					/>
				</button>

				<button
					class="button"
					@click="returnToApp"
				>
					<svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V6zM4 16a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2zm10 0a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2z"/></svg>
	
					<span class="ml-4">
						Fichajes estándar
					</span>
				</button>
			</div>
		</header>

		<!-- Database empty -->
		<div
			v-if="!loading && database.length === 0"
			class="relative w-full h-full overflow-hidden flex items-center justify-center"
		>
			<p class="text-2xl text-white">
				La base de datos está vacía
			</p>
		</div>

		<!-- Database filled -->
		<div 
			v-else-if="!loading" 
			class="w-full h-full"
		>
			<div class="absolute z-0 w-screen h-screen flex">
				<div class="m-auto flex flex-col">
					<svg class="mx-auto w-48 h-48 fill-current text-teal-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path opacity=".2" d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z"/><path d="m26.013 10.047 1.654-2.866a14.855 14.855 0 0 0-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite"/></path></svg>
					
					<p class="text-white text-center">
						Cargando reconocimiento facial
					</p>
				</div>
			</div>

			<Stream
				:active="faceDetection || isEmployeeNotFound"
				class="w-screen h-screen"
				@stream:ready="onStreamReady"
			/>

			<FaceDetection
				:database="database"
				:source="streamVideo"
				:dimensions="streamDimensions"
				:active="faceDetection"
				class="fixed inset-0 w-screen h-screen"
				@faces:match="onFacesMatched"
				@faces:unknown="onFacesUnknown"
			/>

			<FaceInterface
				:active="faceDetection"
				:dimensions="streamDimensions"
				:matches="streamMatches"
				class="fixed inset-0 w-screen h-screen"
			/>

			<SoundDetector
				:source="mediaStream"
				:active="!faceDetection && !isEmployeeNotFound"
				@sound:detected="onSoundDetected"
				@user:detected="startFaceDetection"
			/>

			<EmployeeNotFound
				:source="mediaStream"
				:active="isEmployeeNotFound"
				@close="onEmployeeUpdated"
			/>
		</div>

		<!-- loading state -->
		<div 
			v-else 
			class="w-full h-full flex"
		>
			<div class="m-auto flex flex-col">
				<svg class="mx-auto w-48 h-48 fill-current text-teal-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path opacity=".2" d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z"/><path d="m26.013 10.047 1.654-2.866a14.855 14.855 0 0 0-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite"/></path></svg>
				
				<p class="text-white text-center">
					Descargando empleados a la tablet
				</p>

				<p v-if="total" class="text-2xl text-white text-center">
					{{ loaded }} / {{ total }}
				</p>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref } from 'vue';
	import { useRouter } from 'vue-router';

	import useUrlParams from '@/composables/use-url-params.js';
	import useEmployeeStore from '@/stores/useEmployeeStore';

	import Stream from '@/components/stream';
	import FaceDetection from '@/components/face-detection';
	import FaceInterface from '@/components/face-interface';
	import SoundDetector from '@/components/sound-detector';
	import EmployeeNotFound from '@/components/employee-not-found';
	import EmployeesList from '@/components/employees-list';


	// set url query params to store
	useUrlParams()

	// database
	const {
		getAllEmployees,
		database,
		loading,
		loaded,
		total,
		employees,
		lastFetched,
	} = useEmployeeStore();

	if (!!lastFetched.value && employees.value.length === 0) {
		console.log('must reload employees');
		lastFetched.value = null;
	}

	getAllEmployees();


	// employees list
	const isEmployeesListOpen = ref(false);
	function openEmployeesList() {
		isEmployeesListOpen.value = true;
	}


	// media stream
	const mediaStream = ref()
	const streamVideo = ref()
	const streamDimensions = ref({ width: 0, height: 0 })

	function onStreamReady({ stream, video, dimensions }) {
		mediaStream.value = stream
		streamVideo.value = video
		streamDimensions.value = dimensions
	}

	// face match
	const streamMatches = ref([])
	const isEmployeeNotFound = ref(false)

	let unknownCount = 0
	function onFacesMatched(matches) {
		streamMatches.value = matches

		if (matches.length === 0) {
			if (!faceDetectionTimeout) {
				setFaceDetectionTimeout()
			}

			return
		}

		clearFaceDetectionTimeout()
		unknownCount = 0
	}

	function onFacesUnknown() {
		unknownCount++

		if (unknownCount > 12) {
			unknownCount = 0

			isEmployeeNotFound.value = true
			faceDetection.value = false

			clearFaceDetectionTimeout();
		}
	}

	function onEmployeeUpdated() {
		unknownCount = 0
		isEmployeeNotFound.value = false
	}

	// toggle detection
	const faceDetection = ref(false)
	function startFaceDetection() {
		faceDetection.value = true
	}
	function stopFaceDetection() {
		faceDetection.value = false
	}
	function onSoundDetected() {
		startFaceDetection()
	}

	// face detection timeout
	let faceDetectionTimeout
	const faceDetectionTimeoutTime = 3000

	function setFaceDetectionTimeout() {
		faceDetectionTimeout = setTimeout(() => {
			unknownCount = 0
			isEmployeeNotFound.value = false
			faceDetection.value = false
		}, faceDetectionTimeoutTime)
	}

	function clearFaceDetectionTimeout() {
		clearTimeout(faceDetectionTimeout)
		faceDetectionTimeout = null
	}

	// router
	const router = useRouter()
	function returnToApp() {
		router.go(-1)
	}
</script>
