<template>
	<AudioAnalyzer
		v-slot="{ analysis }"
		:source="source"
		:active="active"
		@audio:analyzed="onAudioAnalyzed"
	>
		<div v-if="active" class="fixed inset-0 w-full h-full flex justify-center items-center bg-gray-800">
			<div class="absolute top-0 p-32 w-full flex flex-col items-center">
				<p class="text-2xl text-white text-center max-w-sm">
					Habla o presiona el botón central para activar el reconocimiento facial.
				</p>
			</div>

			<div
				class="absolute m-auto flex border-2 border-red-500 rounded-full"
				:style="{
					width: `${288 + limit * 10}px`,
					height: `${288 + limit * 10}px`,
				}"
			>
				<div
					class="m-auto flex border-2 border-teal-500 rounded-full transition-all"
					:style="{
						width: `${288 + analysis.level * 10}px`,
						height: `${288 + analysis.level * 10}px`,
					}"
					@click="onUserClick"
				>
					<div class="relative m-auto w-72 h-72 flex justify-center bg-teal-500 rounded-full">
						<svg
							class="m-auto w-24 h-24 stroke-current text-white"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="1"
								d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
							/>
						</svg>
					</div>
				</div>
			</div>

			<div class="absolute bottom-0 p-32 w-full flex flex-col items-center">
				<InputRange 
					v-model="limit" 
					:min="0" 
					:max="120" 
					class="w-96" 
				/>

				<p class="mt-6 text-lg text-gray-100">
					Regula la intensidad de sonido con la que se activará el reconocimiento.
				</p>
			</div>
		</div>
	</AudioAnalyzer>
</template>

<script>
	import { ref, watchEffect } from 'vue'
	import { state, actions } from '@/store'

	import AudioAnalyzer from '@/components/audio-analyzer'
	import InputRange from '@/components/input-range'

	export default {
		name: 'SoundDetector',
		components: {
			AudioAnalyzer,
			InputRange,
		},
		props: {
			source: {
				type: null,
				required: true,
			},
			active: {
				type: Boolean,
				default: false,
			},
			// threshold: {
			// 	type: Number,
			// 	default: 40,
			// },
		},
		emits: ['sound:detected', 'user:detected'],
		setup(props, { emit }) {
			// threshold
			const limit = ref(state.threshold || 40)
			watchEffect(() => actions.setThreshold(limit.value))

			function onAudioAnalyzed({ level }) {
				if (level >= limit.value) {
					emit('sound:detected')
				}
			}

			function onUserClick() {
				emit('user:detected')
			}

			return {
				limit,
				onAudioAnalyzed,
				onUserClick,
			}
		},
	}
</script>
