<template>
    <TransitionRoot 
        as="template"
        :show="open" 
        appear 
    >
        <Dialog 
            as="div" 
            :initialFocus="closeButtonRef"
            class="relative z-10"
            @close="closeDialog" 
        >
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                        >
                            <div class="flex justify-between items-center">
                                <DialogTitle
                                    as="h3"
                                    class="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Empleados validados ({{ employees.length }})
                                </DialogTitle>
                                
                                <span 
                                    ref="closeButtonRef"
                                    class="cursor-pointer"
                                    @click="closeDialog"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </span>
                            </div>

                            <div class="mt-2">
                                <DialogDescription class="text-sm text-gray-500">
                                    En esta lista se recogen todos aquellos empleados que tienen fotos válidas para el reconocimiento facial.
                                    También puedes volver a
                                    <span 
                                        class="text-red-500 underline cursor-pointer"
                                        @click="reloadDatabase"    
                                    >
                                        recargar los empleados.
                                    </span>
                                </DialogDescription>
                            </div>

                            <div class="mt-4">
                                <div class="relative flex items-center bg-gray-200 rounded-md">
                                    <svg class="absolute mx-2 w-5 h-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                    </svg>

                                    <input 
                                        v-model="employeeFilter"
                                        type="text"
                                        placeholder="Buscar..."
                                        class="w-full pl-10 pr-4 py-1 bg-transparent"
                                    />
                                </div>
                            </div>

                            <div class="mt-4 h-64 overflow-y-auto">
                                <div
                                    v-for="employee in filteredEmployees"
                                    :key="employee.id"
                                    class="flex items-center justify-between py-1 border-b"
                                >
                                    <h4 class="text-sm text-gray-800">
                                        {{ employee.name }}
                                    </h4>

                                    <div class="flex items-center space-x-1">
                                        <svg class="w-5 h-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
                                        </svg>

                                        <span class="text-sm text-gray-500 tabular-nums">
                                            {{ employee.faceDescriptors.length }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
        DialogDescription,
    } from '@headlessui/vue';

    import useEmployeeStore from '@/stores/useEmployeeStore';
    import { ref, computed } from 'vue';


    export default {
        name: 'EmployeesList',
        components: {
            TransitionRoot,
            TransitionChild,
            Dialog,
            DialogPanel,
            DialogTitle,
            DialogDescription,
        },
        props: {
            open: {
                type: Boolean,
                default: false,
            }
        },
        emits: ['update:open'],
        setup(porps, { emit }) {
            // dialog
            const closeButtonRef = ref();
            function closeDialog() {
                emit('update:open', false);
            }

            // database
            const {
                database,
                employees,
                lastFetched,
                getAllEmployees,
            } = useEmployeeStore();

            const employeeFilter = ref('');
            const filteredEmployees = computed(() => database.value.filter(e => e.name?.toLowerCase().includes(employeeFilter.value.toLowerCase())));

            function reloadDatabase() {
                lastFetched.value = '';
                employees.value = [];

                getAllEmployees();
                closeDialog();
            }

            return {
                // dialog
                closeButtonRef,
                closeDialog,

                // database
                database,
                employees,
                employeeFilter,
                filteredEmployees,
                reloadDatabase,
            };
        }
    };
</script>