<template>
	<div class="relative">
		<video
			v-show="active"
			ref="video"
			:srcObject="stream"
			autoplay
			playsinline
			muted
			@loadeddata="onStreamReady"
			@play="onStreamPlay"
			class="w-full h-full flip-h object-cover"
		/>

		<div 
			v-show="active"
			class="px-8 py-12 fixed inset-y-0 h-full right-0 flex flex-col items-center justify-center"
		>
			<button 
				class="button button-primary"
				@click="toggleFaceMode"
			>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-label="icon-refresh" class="stroke-current w-6 h-6" data-inject-url="https://app.sesametime.com/assets/icon-refresh.3077f0ba.svg" viewBox="0 0 24 24"><g stroke="currentColor" stroke-linecap="round" stroke-width="1.5"><path d="M4.582 9a8.003 8.003 0 0 1 15.356 2m-.52 4a8.003 8.003 0 0 1-15.356-2"/><path stroke-linejoin="round" d="M4 4.985v4h4m12 10v-4h-4"/></g></svg>
			</button>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted, onBeforeUnmount, watchEffect } from 'vue'
	import { state, actions } from '@/store';
	
	const facingModes = ['user', 'environment'];

	export default {
		name: 'StreamVideo',
		props: {
			constraints: {
				type: Object,
				default: () => ({
					video: true,
					audio: true,
				}),
			},
			active: {
				type: Boolean,
				default: false,
			},
		},
		emits: ['stream:ready', 'stream:play'],
		setup(props, { emit }) {
			// facing mode
			const facingMode = computed(() => state.facingMode || facingModes[0]);
			function setFacingMode(mode=facingModes[0]) {
				console.log('setting mode: ', mode);
				actions.setFacingMode(mode);

				closeStream();
				openStream();
			}
			function toggleFaceMode() {
				facingMode.value === facingModes[0]
					? setFacingMode(facingModes[1])
					: setFacingMode(facingModes[0])
			}

			// video
			const video = ref()

			// stream
			const stream = ref()
			async function openStream() {
				try {
					stream.value = await navigator.mediaDevices.getUserMedia({
						...props.constraints,
						video: {
							...props.constraints?.video,
							facingMode: facingMode.value,
						},
					})
				} catch (error) {
					console.log('An error courred while open the camera stream.')
				}
			}

			function closeStream() {
				if (!stream.value) {
					return
				}

				canPlayFeedback.value = false
				stream.value.getTracks().forEach(track => {
					track.stop()
				})
			}

			// stream ready
			const canPlayFeedback = ref(false)
			function onStreamReady() {
				console.log('stream ready!!!');


				canPlayFeedback.value = true

				emit('stream:ready', {
					video: video.value,
					stream: stream.value,
					dimensions: getDimensions(),
				})
			}

			function onStreamPlay() {
				emit('stream:play')
			}

			function getDimensions() {
				return { width: video.value.videoWidth, height: video.value.videoHeight }
			}

			onMounted(() => openStream())
			onBeforeUnmount(() => closeStream())

			return {
				video,
				stream,
				canPlayFeedback,

				facingMode,
				toggleFaceMode,

				onStreamReady,
				onStreamPlay,
			}
		},
	}
</script>
