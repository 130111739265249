<template>
	<div v-if="active && faces.length > 0" class="fixed inset-0 w-screen h-screen pointer-events-none">
		<svg :viewBox="viewBox" class="w-screen h-screen object-cover">
			<!-- Masks -->
			<!-- <defs>
				<mask id="hole">
					<rect width="100%" height="100%" fill="white" />

					<circle
						v-for="face in faces"
						:key="face.id"
						:r="face.radius"
						:cx="face.center.x"
						:cy="face.center.y"
						fill="black"
						class="transition-all duration-300"
					/>
				</mask>
			</defs> -->

			<!-- Background -->
			<!-- <rect
				width="100%"
				height="100%"
				mask="url(#hole)"
				class="fill-current text-black opacity-75"
			/> -->

			<!-- Interface -->
			<g v-for="(face, index) in faces" :key="index">
				<circle
					:r="face.radius"
					:cx="face.center.x"
					:cy="face.center.y"
					stroke-width="2"
					fill="transparent"
					class="stroke-current text-gray-500 transition-all duration-300"
				/>

				<circle
					:r="face.radius"
					:cx="face.center.x"
					:cy="face.center.y"
					:stroke-width="face.radius * 0.1"
					:stroke-dasharray="2 * Math.PI * face.radius"
					:stroke-dashoffset="face.progress * 2 * Math.PI * face.radius"
					fill="transparent"
					class="stroke-current text-blue-500 transition-all duration-300"
				/>

				<circle
					v-if="face.disabled && face.status"
					:r="face.radius"
					:cx="face.center.x"
					:cy="face.center.y"
					stroke-width="4"
					fill="transparent"
					class="stroke-current"
					:class="{
						'text-teal-500': face.status === 'online',
						'text-orange-500': face.status === 'offline',
					}"
				/>

				<text
					:x="face.center.x"
					:y="face.center.y + face.radius * 1.1 + 25"
					dominant-baseline="middle"
					text-anchor="middle"
					class="fill-current text-white text-2xl transition-all duration-300"
				>
					<tspan class="">{{ face.name }}</tspan>
				</text>

				<!-- text states -->
				<text
					:x="face.center.x"
					:y="face.center.y + face.radius * 1.15 + 40"
					dominant-baseline="middle"
					text-anchor="middle"
					class="text-lg transition-all duration-300"
				>
					<tspan
						v-if="face.disabled && face.status === 'online'"
						class="fill-current text-teal-500"
					>
						Hola!
					</tspan>
					<tspan
						v-else-if="face.disabled && face.status === 'offline'"
						class="fill-current text-orange-500"
					>
						Adiós!
					</tspan>
					<tspan v-else-if="face.id === 'unknown'" class="fill-current text-red-500"></tspan>

					<tspan v-else-if="!face.loading" class="fill-current text-blue-500">
						Quédate quiet@ mientras la app te reconoce...
					</tspan>
					<tspan v-else-if="face.loading" class="fill-current text-blue-500">Fichando...</tspan>
				</text>
			</g>
		</svg>
	</div>
</template>

<script>
	import { computed, ref, watch } from 'vue'
	import Employees from '@/api/endpoints/Employees.js'
	import EmployeeCheck from '@/api/endpoints/EmployeeCheck.js'

	import throttle from 'lodash/throttle'

	export default {
		name: 'FaceInterface',
		props: {
			matches: {
				type: Array,
				required: true,
			},
			dimensions: {
				type: Object,
				required: true,
				validator(value) {
					return value.hasOwnProperty('width') && value.hasOwnProperty('height')
				},
			},
			active: {
				type: Boolean,
				default: false,
			},
		},
		setup(props) {
			// svg dimensions
			const viewBox = computed(() => {
				return `0 0 ${props.dimensions.width} ${props.dimensions.height}`
			})

			// faces
			const faces = computed(() => {
				return props.matches.map(match => {
					const { id, name = '', detection } = match

					const memory = employeesRecord.value[id]

					const progress = (1 - ((memory?.count || 0) * 1) / maxCount).toFixed(2)
					const disabled = memory?.disabled
					const status = memory?.status
					const loading = memory?.loading

					return {
						...match,
						name,
						progress,
						disabled,
						status,
						loading,
						...detection.circle,
					}
				})
			})

			// employees record
			const employeesRecord = ref({})
			const frequency = 1000
			const maxCount = 2 // seconds to wait until check
			const forgetTimeoutTime = 2000

			function resetRecord(id) {
				employeesRecord.value[id] = {
					count: 0,
				}
			}
			function resetRecordCount(id) {
				employeesRecord.value[id].count = 0
			}
			function addRecordCount(id) {
				employeesRecord.value[id].count++
			}
			function forgetRecordCount(id) {
				clearTimeout(employeesRecord.value[id].forget)

				employeesRecord.value[id].forget = setTimeout(function () {
					employeesRecord.value[id].count = 0
				}, frequency + forgetTimeoutTime)
			}
			function enableRecord(id) {
				employeesRecord.value[id].disabled = false
			}
			function disableRecord(id) {
				employeesRecord.value[id].disabled = true
			}
			function enableAllRecords() {
				for (const id in employeesRecord.value) {
					if (Object.hasOwnProperty.call(employeesRecord.value, id)) {
						employeesRecord.value[id].disabled = false
					}
				}
			}
			function setStatus(id, status) {
				employeesRecord.value[id].status = status
			}
			function setCheckLoading(id, value) {
				employeesRecord.value[id].loading = value
			}

			// detection
			const faceDetection = throttle(
				async function ({ id, name, code, pin }) {
					if (id === 'unknown') {
						return
					}

					// first time on database - reset record
					if (!employeesRecord.value[id]) {
						resetRecord(id)
					}

					const { count, disabled, forget } = employeesRecord.value[id]

					// disabled
					if (disabled) {
						// this user can't use the interface
						console.log('CANCELLED')
						return
					}

					// last time
					if (count >= maxCount) {
						clearTimeout(forget)

						disableRecord(id)
						setStatus(id, null)

						const employee = await Employees.find({
							employeeCode: new String(code),
							employeePin: new String(pin),
						})

						const data = {
							code: new String(code),
							pin: new String(pin),
							type: 'work',
						}

						setCheckLoading(id, true)

						switch (employee.workStatus) {
							case 'online':
								await EmployeeCheck.out(data)
								setStatus(id, 'offline')
								break
							default:
								await EmployeeCheck.in(data)
								setStatus(id, 'online')
								break
						}

						setCheckLoading(id, false)
						// console.log(name, ' acaba de fichar con codigo: ', code, ' y pin: ', pin)
						resetRecordCount(id)

						return
					}

					// other times
					addRecordCount(id)
					forgetRecordCount(id)
				},
				frequency, // enable detection every 'frequency' milliseconds
				{ trailing: false }
			)

			watch(
				() => faces.value,
				items => {
					if (items.length === 0) {
						enableAllRecords()

						return
					}

					items.forEach(face => {
						faceDetection(face)
					})
				}
			)

			return {
				// svg dimensions
				viewBox,

				// faces
				faces,
			}
		},
	}
</script>
